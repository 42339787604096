.game-timeline__event.active {
  z-index: 1000;
  background-color: #ffffff8e;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}
.game-timeline__event.game-timeline__event--ft.active {
  background-color: transparent;
}

.game-timeline__time.active {
  z-index: 1000;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
}

.game-timeline__event .icon-svg.icon-basketball {
  font-size: 18px !important;
}

.game-timeline__event .icon-svg.invalid {
  filter: grayscale(1);
}

.game-timeline__time {
  width: 30px;
  height: 30px;
  overflow: hidden;
  line-height: 30px !important;
}
